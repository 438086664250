<template>
  <div class="scheduled-geogrid-details">
    <button class="scheduled-geogrid-details__back btn btn-sm btn-pretender"
      @click="$emit('hideConfigDetails')">
      <i class="pr-2 fa-regular fa-arrow-left-long" />
      <span>Back to search terms</span>
    </button>
    <div class="scheduled-geogrid-details__top">
      <component id="open-location" class="scheduled-geogrid-details__name" :is="attrs.locationPath ? 'a' : 'span'"
        target="_blank"
        :href="attrs.locationPath">
        <span>{{ attrs.businessName }}</span>
        <i v-if="attrs.locationPath" class="fa-regular fa-arrow-up-right-from-square" />
      </component>
      <b-popover
        v-if="attrs.locationPath"
        placement='top'
        target="open-location"
        custom-class='geogrids-popover p-1'
        triggers='hover'>
        Open location page
      </b-popover>
    </div>
    <p v-if="attrs.businessStoreCode" class="scheduled-geogrid-details__store-code">{{ attrs.businessStoreCode }}</p>
    <p class="scheduled-geogrid-details__address">{{ attrs.businessAddress ? attrs.businessAddress : 'Service Area Business' }}</p>
    <CategoriesList v-if="attrs.businessCategories?.length" :categories="attrs.businessCategories" />
    <div v-if="config.labels && config.labels.length"
      class="scheduled-geogrid-details__labels">
      <div class="label my-2">
        <span class="location-labels-caption">
          <img src="/packs/images/852296e025cf04825756.svg" alt="Google logo">
          Label:
        </span>
      </div>
      <div class="location-labels">
        <span v-for="label in config.labels" :key="`${label}${config.obfuscatedId}`" class="location-labels-item">{{ label }}</span>
      </div>
    </div>
    <button class="scheduled-geogrid-details__copy btn btn-semi-primary" @click="copyPlaceId">
      <span>Copy Place ID</span>
      <i class="far fa-copy" />
    </button>
    <hr class="scheduled-geogrid-details__separator">
    <div class="scheduled-geogrid-details__schedule">
      <div>
        <p>Schedule:</p>
        <span>{{ config.scheduleFormatted }}</span>
      </div>
      <div>
        <p>Next generation:</p>
        <span>{{ config.nextGeneration }}</span>
      </div>
    </div>
    <Tags ref="tags"
      :initial-tags="config.tags"
      :show-edit-buttons="true"
      :add-tag-path="config.addTagPath"
      :remove-tag-path="config.removeTagPath" />
    <div class="scheduled-geogrid-details__note">
      <span class="label" v-show="openNote || note.length">Note:</span>
      <div class="scroll ver" :class="{'config-note': !openNote}">
        <quick-edit
          empty-text="Click here to write a note"
          type="textarea"
          :classes="vueQuickEditClasses"
          :show-buttons="false"
          @close="updateNote"
          @show="setNote"
          v-model="note" />
      </div>
    </div>
    <a v-if="config.publicUrl" class="btn btn-sm" :href="config.publicUrl" target="_blank">
      <i class="far fa-globe mr-2" />
      Open public page
    </a>
    <a :href="config.comparisonUrl" target="_blank" class='btn btn-link' :id="`compare-config-${config.obfuscatedId}`">
      <i class='far fa-crosshairs' />
      Compare config's geogrids
    </a>
    <button class="btn btn-sm" @click="repeatConfig">
      <i class="far fa-copy mr-2" />
      Duplicate config
    </button>
  </div>
</template>

<script>
import QuickEdit from 'vue-quick-edit'
import toastr from 'toastr'
import axios from 'axios'
import { mapMutations, mapState } from 'vuex'

import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'

import Tags from '../../components/tags'
import CategoriesList from '../../components/categories_list'

export default {
  components: {
    Tags,
    CategoriesList,
    QuickEdit
  },
  data() {
    return {
      vueQuickEditClasses: {
        link: 'text-break vue-quick-edit__link',
        input: 'form-control form-control-sm textareaClass'
      },
      note: '',
      gifDate: null,
      getGifLink: null,
      generateGifLink: null,
      placesDataSources: {
        crawl: 'Standard search',
        placesApi: 'Google Places API Key'
      },
      openNote: false
    }
  },
  computed: {
    ...mapState(['config']),
    attrs() {
      return this.config.attrs
    }
  },
  watch: {
    config: {
      handler(newVal) {
        if (!newVal.obfuscatedId) return
        this.note = newVal.note || ''
        this.schedule = newVal.scheduleFormatted
        this.$refs.tags?.renderTags(newVal.tags)
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations([
      'repeatConfig'
    ]),
    updateNote() {
      this.openNote = false
      const noteWithNoEmptyLines = this.textarea.value.toString().trim()
      this.config.note = noteWithNoEmptyLines
      this.note = noteWithNoEmptyLines
      axios.patch(this.config.updateNotePath, { note: noteWithNoEmptyLines }).catch((_error) => {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      })
    },
    setNote() {
      this.openNote = true
      this.$nextTick(() => {
        [this.textarea] = $('.textareaClass')
      })
    },
    copyPlaceId(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.attrs.businessPlaceId })
    }
  }
}
</script>
