<template>
  <div class="datepicker-lv">
    <i class="datepicker-lv-icon far fa-calendar-day" @click.stop="toogleFlatpickr" />
    <flat-pickr ref="datePicker" :value="null" :config="config" :placeholder="placeholder" />
    <i class="datepicker-lv-arrow" @click.stop="toogleFlatpickr" />
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

import { FLATPICKR_WEEKDAYS_MAP } from 'common/constants'

export default {
  components: { flatPickr },
  props: {
    mode: { type: String, default: 'single' },
    dateFormat: { type: String, default: 'm/d/Y' },
    maxDate: { type: [String, Date], default: null },
    minDate: { type: [String, Date], default: null },
    justMonths: { type: Boolean, default: false },
    value: { type: [Array], default: null },
    emitWhenCreate: { type: Boolean, default: false },
    placeholder: { type: String, default: '' }
  },
  data() {
    return {
      prevDate: null,
      isCreating: true
    }
  },
  created() {
    const en = { rangeSeparator: ' – ' }
    this.config = {
      mode: this.mode,
      altFormat: 'M j, Y',
      altInput: true,
      allowInput: true,
      altInputClass: 'datepicker-lv-input',
      dateFormat: this.dateFormat,
      maxDate: this.maxDate,
      minDate: this.minDate,
      disableMobile: true,
      locale: {
        ...en,
        firstDayOfWeek: FLATPICKR_WEEKDAYS_MAP[Styxie?.USER?.beginningOfWeek || 'sunday']
      },
      onChange: ([start, end], dateStr, instance) => {
        if (this.mode === 'range' && start && end) {
          this.$emit('input', [
            instance.formatDate(start, this.dateFormat),
            instance.formatDate(end, this.dateFormat)
          ])
        }
        if (this.mode === 'single') {
          this.$emit('input', [instance.formatDate(start, this.dateFormat)])
        }
      },
      onOpen: (selectedDates) => {
        this.prevDate = selectedDates
      },
      onClose: (selectedDates, dateStr, instance) => {
        if (this.mode === 'range' && selectedDates.length === 1) {
          if (this.prevDate.length === 2) {
            instance.setDate([this.prevDate[0], this.prevDate[1]], false)
          } else {
            instance.setDate([], false)
          }
        }
      },
      onYearChange: ([start], dateStr, instance) => {
        if (this.justMonths) {
          const currentYear = new Date().getFullYear()
          const months = instance.innerContainer.querySelectorAll('.flatpickr-monthSelect-month')

          if (instance.currentYear !== currentYear) {
            instance.innerContainer.querySelector('.selected')?.classList.remove('selected')
            months.forEach((element) => {
              element.classList.remove('flatpickr-disabled')
            })
          } else {
            months[start.getMonth()].classList.add('selected')
            months.forEach((element, index) => {
              if (index > this.maxDate.getMonth()) {
                element.classList.add('flatpickr-disabled')
              }
            })
          }
        }
      }
    }

    if (this.justMonths) {
      this.config.plugins = [new MonthSelectPlugin()]
    }

    this.$nextTick(() => {
      if (this.value) {
        this.$refs.datePicker?.fp.setDate(this.value, false, this.dateFormat)
      }

      if (this.emitWhenCreate) {
        const range = this.$refs.datePicker.fp.selectedDates
        this.$emit('input', range.map((date) => this.$refs.datePicker.fp.formatDate(date, this.dateFormat)))
      }

      this.$refs.datePicker?.$el.nextElementSibling.setAttribute('readonly', 'readonly')
    })
  },
  methods: {
    toogleFlatpickr() {
      this.$refs.datePicker.fp.toggle()
    }
  },
  watch: {
    value() {
      this.$refs.datePicker?.fp.setDate(this.value, false, this.dateFormat)
    }
  }
}
</script>
