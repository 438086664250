import Vue from 'vue'
import GeogridsComparison from 'vue_widgets/stats/gmb/geogrid_configs/comparison_list'

Styxie.Initializers.StatsGmbGeogridConfigs = {
  compare: (props) => {
    Styxie.USER = {
      beginningOfWeek: 0
    }

    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-geogrids-comparison'),
        render: (h) => h(GeogridsComparison, { props })
      })
    })
  }
}
