<template>
  <div class="py-3">
    <div class="geogrid-comparison__container">
      <div class="geogrid-comparison__common-info geogrid-comparison__common-info--bg-white p-4 mb-2">
        <span class="location-name">{{ businessName }}</span>
        <span v-if="businessStoreCode" class="location-store-code">{{ businessStoreCode }}</span>
        <span>{{ businessAddress ?? 'Service Area Business' }}</span>
      </div>
      <div class="geogrid-comparison__common-info p-4 mb-2 no-print">
        <div class="align-items-end d-flex">
          <div class="form-field">
            <label class="m-0">By date</label>
            <DatePicker
              class="w-100"
              mode="range"
              :min-date="minDate"
              :max-date="maxDate"
              v-model="byPeriod" />
          </div>
          <button
            v-if="byPeriod.length && minDate != maxDate"
            class="btn btn-link btn--rem gap-3"
            @click="byPeriod = [minDate, maxDate]">
            <i class="far fa-eraser" /><span>Reset filter</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="rows.length" class="geogrid-comparison">
      <div class="geogrid-comparison__container">
        <ComparisonLine v-for="row in rows"
          :assets-host="assetsHost"
          :key="row.searchTerm"
          :row="row" />
      </div>
    </div>
    <div v-else-if="!loading" class="geogrid-comparison">
      <div class="geogrid-comparison__container">
        <p class="font-weight-bold mt-3 p-4 text-dark">
          No data available for the selected range
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'
import axiosTransform from 'common/axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import DatePicker from 'vue_widgets/components/datepicker'
import ComparisonLine from './comparison_line.vue'

export default {
  name: 'GeogridsComparison',
  components: {
    ComparisonLine, DatePicker
  },
  props: {
    businessName: String,
    businessStoreCode: String,
    businessAddress: String,

    comparisonSourcePath: {
      type: String,
      required: true
    },
    assetsHost: {
      type: String,
      required: true
    },
    minDate: {
      type: String,
      required: true
    },
    maxDate: {
      type: String,
      required: true
    },
    initialRows: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      byPeriod: [this.minDate, this.maxDate],
      rows: this.initialRows,
      loading: false
    }
  },
  computed: {
    filters() {
      return {
        byPeriod: this.byPeriod
      }
    }
  },
  methods: {
    async fetchComparisonRows() {
      this.loading = true
      try {
        const response = await axios.get(this.comparisonSourcePath, {
          params: snakecaseKeys(this.filters),
          ...axiosTransform
        })
        this.rows = response.data
      } catch {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    filters: {
      handler() {
        this.fetchComparisonRows()
      },
      deep: true
    }
  }
}
</script>
