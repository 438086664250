<template>
  <div class='d-flex flex-column min-h-100 mt-3'
    :class="{'px-3': !isLiveShow }">
    <div class="geogrids-widget-container">
      <div v-show='loader' class='loader'><i class='far fa-spinner fa-pulse fa-fw' /></div>
      <tabs v-if="pageState !== 'live' || pageTab === 'new'" />
      <div v-if="pageState === 'live' && pageTab === 'index'"
        class="d-flex flex-wrap justify-content-between">
        <h1 class="text-dark">Live geogrids</h1>
        <button class="btn btn-primary" @click="goTo('new')">
          <i class="text-xl fa-sharp fa-solid fa-plus" />
          <span class="pl-2">New geogrid</span>
        </button>
      </div>
      <newGeogrid
        :gmb-locations-path='gmbLocationsPath'
        :keys-path='keysPath'
        :distance-measure-options='distanceMeasureOptions'
        :places-api-keys='defaultPlacesApiKeys'
        :point-distances='pointDistances'
        :api-geogrids-path='apiGeogridsPath'
        :all-locations='allLocations'
        @reloadHistory='reloadHistory'
        v-show="pageState === 'live' && pageTab === 'new'" />
      <geogridsHistory
        ref='history'
        :geogrids-path='geogridsPath'
        :api-geogrids-path='apiGeogridsPath'
        v-if="pageState === 'live' && pageTab === 'index'" />
      <scheduledGeogridsConfigs
        ref='configs'
        :api-configs-path='apiConfigsPath'
        :api-total-configs-path='apiTotalConfigsPath'
        v-if="pageState === 'scheduled' && pageTab ==='index'" />
      <newConfig
        class="w-100"
        :api-geogrids-path='apiGeogridsPath'
        :periodicity-options='periodicityOptions'
        :day-number-options='dayNumberOptions'
        :week-number-options='weekNumberOptions'
        :keys-path='keysPath'
        :distance-measure-options='distanceMeasureOptions'
        :places-api-keys='defaultPlacesApiKeys'
        :api-configs-path='apiConfigsPath'
        :gmb-locations-path='gmbLocationsPath'
        :all-locations='allLocations'
        :user-time-zone='userTimeZone'
        :time-zones='timeZones'
        @createdNewConfig='createdNewConfig'
        v-show="pageState === 'scheduled' && pageTab === 'new'" />
      <editConfig
        ref='editConfig'
        :periodicity-options='periodicityOptions'
        :day-number-options='dayNumberOptions'
        :week-number-options='weekNumberOptions'
        :new-key-path='newKeyPath'
        :keys-path='keysPath'
        :point-distances='pointDistances'
        :places-data-source-default-options='placesDataSourceDefaultOptions'
        :distance-measure-options='distanceMeasureOptions'
        :places-api-keys='defaultPlacesApiKeys'
        :api-configs-path='apiConfigsPath'
        :user-time-zone='userTimeZone'
        :time-zones='timeZones'
        @reloadHistory='reloadHistory'
        v-show="pageState === 'scheduled' && pageTab === 'edit'" />
      <div v-if="pageTab !== 'index' && selectedCompetitor" class="d-flex align-items-center p-2">
        <button class="btn btn-sm btn-pretender"
          @click="setSelectedCompetitor(null)">
          <i class="pr-2 fa-regular fa-arrow-left-long" />Back to main location geogrid
        </button>
        <p v-if="!(pageTab === 'show' && pageState ==='live')" class="m-0 font-weight-bold">
          <span class="text-grey">Search term:</span>
          <span class="text-dark">{{ geogrid.searchTerm }}</span>
        </p>
      </div>
      <div v-show="pageTab !== 'index'"
        :class="['position-relative configs-show', mapWrapperClasses]">
        <ConfigAside v-if="isScheduledShow && !selectedCompetitor" @setShowFullConfig="showConfigDetailsExpanded = $event" />
        <geogridDetails v-if="isLiveShow && !selectedCompetitor"
          :api-geogrids-path='apiGeogridsPath'
          @reloadHistory='reloadHistory' />
        <div class="position-relative configs-map d-flex flex-column gap-2">
          <CompetitorsScore v-if="showCompetitorsScore && !selectedCompetitor"
            @close="setCompetitorsScoreState(false)"
            @showCompetitorGeogrid="setSelectedCompetitor" />
          <SelectedCompetitorCard v-if="selectedCompetitor"
            class="inmap left top"
            :competitor="selectedCompetitor" />
          <geoAreaMap
            ref='map'
            :show-config-details-expanded="showConfigDetailsExpanded"
            :competitor-geogrid-is-selected="selectedCompetitor"
            :geogrids-path='geogridsPath'
            :point-distances='pointDistances'
            :distance-measure-options='distanceMeasureOptions'
            @showCompetitorsScore="setCompetitorsScoreState(true)" />
          <slider
            v-if="!selectedCompetitor && !showCompetitorsScore && !showConfigDetailsExpanded"
            ref='slider'
            :is-scheduled-show="isScheduledShow"
            @reloadHistory='reloadHistory'
            v-show="isScheduledShow" />
        </div>
        <businessByPoint
          :class="{ 'scheduled': pageState === 'scheduled' }"
          :active="showBusinessByPoint"
          ref="businessByPoint"
          @setState="setBusinessByPointState"
          @showCompetitorGeogrid="setSelectedCompetitor" />
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr'
import axios from 'axios'
import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import {
  BForm, BFormGroup, BFormInput, BFormSelect, BFormRadio, BFormRadioGroup, BFormCheckbox, BTooltip, BPopover, BButton, BRow, BContainer, BCol
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import geogridsHistory from './live/index'
import geogridDetails from './live/show'
import newGeogrid from './live/new'
import CompetitorsScore from './map/competitors_score'
import SelectedCompetitorCard from './competitors/selected_competitor_card'
import geoAreaMap from './map/map'
import tabs from './tabs'
import businessByPoint from './map/business_by_point'
import scheduledGeogridsConfigs from './scheduled/index'
import editConfig from './scheduled/edit'
import newConfig from './scheduled/new'
import slider from './scheduled/show/slider'
import ConfigAside from './scheduled/show/config_aside'
import { DEFAULT_ERROR_MESSAGE } from '../../common/constants'
import { isEmpty, fetchFromLocalStorage } from '../../common/helpers'

Vue.component('BForm', BForm)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BFormSelect', BFormSelect)
Vue.component('BFormRadio', BFormRadio)
Vue.component('BFormRadioGroup', BFormRadioGroup)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('BTooltip', BTooltip)
Vue.component('BPopover', BPopover)
Vue.component('BButton', BButton)
Vue.component('BRow', BRow)
Vue.component('BContainer', BContainer)
Vue.component('BCol', BCol)

const GEOGRID_ATTRS_PRESETS_MAPPING = [
  'gridSize', 'gridDistanceMeasure', 'gridPointDistance',
  'localLanguageEnabled', 'shouldNotify'
]

const csrf = document.querySelector('meta[name="csrf-token"]')
axios.defaults.headers.common['X-CSRF-Token'] = csrf.getAttribute('content')
function preloadImage(url) {
  const image = new Image()
  return new Promise((resolve, reject) => {
    image.onload = resolve
    image.onerror = reject
    image.src = url
  })
}

export default {
  name: 'GeogridsWidget',
  components: {
    tabs,
    CompetitorsScore,
    SelectedCompetitorCard,
    geoAreaMap,
    geogridsHistory,
    geogridDetails,
    newGeogrid,
    businessByPoint,
    scheduledGeogridsConfigs,
    editConfig,
    newConfig,
    slider,
    ConfigAside
  },
  props: {
    // geogrids: Array,
    // totalGeogrids: Number,
    cable: Object,
    newKeyPath: String,
    keysPath: String,
    apiGeogridsPath: String,
    geogridsPath: String,
    apiConfigsPath: String,
    isAdmin: Boolean,
    apiTotalConfigsPath: String,
    pointDistances: Object,
    periodicityOptions: Object,
    dayNumberOptions: Array,
    weekNumberOptions: Array,
    placesDataSourceDefaultOptions: Array,
    distanceMeasureOptions: Array,
    defaultPlacesApiKeys: Array,
    gmbLocationsPath: String,
    allLocations: Array,
    restoreGeogridFormState: Boolean,
    userTimeZone: String,
    timeZones: Array
  },
  data: () => ({
    showBusinessByPoint: false,
    showCompetitorsScore: false,
    competitorSelectedFromBusinessByPoint: false,
    selectedCompetitor: null,
    showConfigDetailsExpanded: false
  }),
  mounted() {
    this.parseUrlAndGo()
    window.onpopstate = () => {
      this.parseUrlAndGo()
    }
    this.setMap('map')
    this.listenGeogridCreatedCable()
    this.listenGeogridImageGeneratedCable()
    this.listenSearchTermGifGeneratedCable()
    window.addEventListener('resize', this.computeMainDivHeight)

    // maintenance, remove after
    const maintenance = document.createElement('div')
    maintenance.innerHTML = 'Geogrids might have some missed spots. We are already fixing the problem. Sorry for the inconvenience.'
    maintenance.classList.add('maintenance-banner')
    document.body.prepend(maintenance)
  },
  created() {
    this.setGeogridAttrsPresets()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.computeMainDivHeight)
  },
  computed: {
    ...mapState(['config', 'geogrid', 'loader', 'searchTerm', 'pageTab']),
    pageState: {
      get() { return this.$store.state.pageState },
      set(val) { this.$store.commit('pageState', val) }
    },
    config: {
      get() { return this.$store.state.config },
      set(val) { this.$store.commit('config', val) }
    },
    isScheduledShow() {
      return this.pageState === 'scheduled' && this.pageTab === 'show'
    },
    isLiveShow() {
      return this.pageState === 'live' && this.pageTab === 'show'
    },
    mapWrapperClasses() {
      return {
        'big-map': (this.isScheduledShow && !this.selectedCompetitor) || (this.isLiveShow && !this.selectedCompetitor),
        'pl-3': !this.isScheduledShow && !this.isLiveShow,
        'pr-3': !this.isLiveShow
      }
    }
  },
  watch: {
    isScheduledShow() {
      this.$nextTick(() => {
        this.computeMainDivHeight()
      })
    },
    isLiveShow() {
      this.$nextTick(() => {
        this.computeMainDivHeight()
      })
    },
    pageTab() {
      this.setBusinessByPointState(false)
      this.setCompetitorsScoreState(false)
      this.showConfigDetailsExpanded = false

      if (this.pageTab !== 'edit') {
        this.setSelectedCompetitor(null)
      }
    },
    pageState() {
      this.setBusinessByPointState(false)
      this.setCompetitorsScoreState(false)
      this.showConfigDetailsExpanded = false
    },
    'searchTerm.text': {
      handler() {
        this.setCompetitorsScoreState(false)
      }
    }
  },
  methods: {
    ...mapMutations([
      'setMap',
      'setGeogridImage',
      'updateNewGifData']),
    ...mapActions(['parseUrlAndGo', 'goTo', 'loadConfigShowPageData', 'showGeogrid']),
    createdNewConfig(createdConfig) {
      this.reloadHistory()
      this.config = createdConfig
      this.goTo('show')
    },
    reloadHistory() {
      this.$refs.history?.reload()
    },
    listenGeogridCreatedCable() {
      const sub = this.cable.subscriptions.create('PlacesChannel', {
        received: async(message) => {
          if (message.type === 'geogrid.finished' || message.type === 'geogrid.error') {
            this.reloadHistory()

            await this.loadConfigShowPageData({
              configId: message.config_id,
              keyword: message.geogrid.search_term
            })
            this.showGeogrid({ geogrid: message.geogrid })
          }
        }
      })
      $(document).one('turbolinks:before-visit turbolinks:visit turbolinks:click', () => {
        this.cable.subscriptions.remove(sub)
      })
    },
    updateImageButton(id, url) {
      const histComp = this.$refs.history
      const geogridOnIndex = histComp.$refs?.table?.rows?.find((gr) => gr?.obfuscatedId === id)

      if (geogridOnIndex) {
        geogridOnIndex.imagePublicPath = url
        const dropdownImageBtnComp = histComp.$refs[`live-config-row-${id}`]?.$refs[`dropdownActions-${id}`]?.$refs[`${id}-button-geogrid-get-image`]
        dropdownImageBtnComp.imageGenerating = false
      }
      if (this.geogrid.obfuscatedId === id) this.setGeogridImage(url)
    },
    listenGeogridImageGeneratedCable() {
      const sub = this.cable.subscriptions.create('GenerateGeogridImageChannel', {
        received: ({
          id, type, url, title
        }) => {
          if (type === 'image:generate:success') {
            preloadImage(url).then(() => {
              toastr.success(
                `<a href='${url}' class='geogrid-image-link' target='_blank'>${title}</a>
                 <a href='${url}' class='geogrid-image-preview' target='_blank'><div><img src='${url}'/></div></a>`,
                'Your image is ready',
                { positionClass: 'geogrid-image toast-top-right' }
              )
            }).catch(() => {
              toastr.success(`<a href='${url}' class='geogrid-image-link' target='_blank'>${title}</a>`, 'Your image is ready')
            })
            this.updateImageButton(id, url)
          } else {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        }
      })
      $(document).one('turbolinks:before-visit', () => {
        this.cable.subscriptions.remove(sub)
      })
    },
    // It wont recognize attributes, if they will be CamelCase
    /* eslint-disable camelcase */
    listenSearchTermGifGeneratedCable() {
      const sub = this.cable.subscriptions.create('GenerateGeogridsHistoryAnimationChannel', {
        received: (data) => {
          const {
            url,
            businessName,
            searchTerm,
            configId,
            gifDate,
            status
          } = camelCaseKeys(data)
          if (status) {
            // wait to previous toastr to disapear so positionClass get updated
            setTimeout(() => {
              preloadImage(url).then(() => {
                toastr.success(
                  `<a href='${url}' class='geogrid-image-link' target='_blank'>${businessName}: ${searchTerm}.</a>
                   <a href='${url}' class='geogrid-image-preview' target='_blank'><div><img src='${url}'/></div></a>`,
                  'Your GIF is ready',
                  { positionClass: 'geogrid-image toast-top-right' }
                )
              }).catch(() => {
                toastr.success(
                  `<a href='${url}' class='geogrid-image-link' target='_blank'>${businessName}: ${searchTerm}.</a>`,
                  'Your GIF is ready'
                )
              })
            }, 2000)
            if (this.pageState === 'scheduled' &&
              this.config.obfuscatedId === configId &&
              this.searchTerm.text === searchTerm) {
              this.updateNewGifData({ url, date: gifDate })
            }
          } else {
            toastr.error(`GIF for <u>${businessName}</u> has failed. ${DEFAULT_ERROR_MESSAGE}`, { timeOut: 0 })
          }
        }
      })
      $(document).one('turbolinks:before-visit', () => {
        this.cable.subscriptions.remove(sub)
      })
    },
    /* eslint-enable camelcase */
    setGeogridAttrsPresets() {
      if (!this.restoreGeogridFormState) return
      const attrs = fetchFromLocalStorage(GEOGRID_ATTRS_PRESETS_MAPPING)

      if (isEmpty(attrs)) return

      this.$store.commit('setAttrsPresets', attrs)
    },
    computeMainDivHeight() {
      const bigMapContainer = document.querySelector('.geogrids-widget-container .big-map')
      if (!bigMapContainer) return

      bigMapContainer.style = `min-height: ${window.innerHeight - bigMapContainer.offsetTop - 45}px`
    },
    setBusinessByPointState(newState) {
      this.showBusinessByPoint = newState
    },
    setCompetitorsScoreState(state) {
      this.showCompetitorsScore = state

      // workaround for this print css only affecting competitors score print when its open
      document.querySelector('html').classList.toggle('competitors-score-print', state)
    },
    async setSelectedCompetitor(newSelectedCompetitor) {
      if (!newSelectedCompetitor) {
        this.showGeogrid({ geogrid: this.geogrid })
        this.selectedCompetitor = null
        this.setBusinessByPointState(this.competitorSelectedFromBusinessByPoint)
        this.competitorSelectedFromBusinessByPoint = false
        return
      }

      this.competitorSelectedFromBusinessByPoint = this.showBusinessByPoint

      const { competitor, index } = newSelectedCompetitor
      const { placeId } = competitor
      const hrefUrl = new URL(window.location.href)
      const urlParams = new URLSearchParams(hrefUrl.search)
      urlParams.append('place_id', placeId)
      window.history.pushState(null, document.title, `${window.location.pathname}?${urlParams}`)
      await this.showGeogrid({ geogrid: this.geogrid, isCompetitor: true })

      urlParams.delete('place_id')
      window.history.pushState(null, document.title, `${window.location.pathname}?${urlParams}`)

      this.selectedCompetitor = {
        order: index,
        ...competitor
      }
    }
  }
}
</script>
