import Vue from 'vue'
import VueKeywordsTable from 'vue_widgets/gmb/keywords/keyword_table'
import keywordsSettingsStore from '../../vue_widgets/gmb/keywords/settings_store'

function initKeywords(params) {
  if (params.hasKeywords) {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-keywords-table'),
      store: keywordsSettingsStore(params),
      render: (h) => h(VueKeywordsTable, {
        ref: 'keywordsTable',
        props: params
      })
    })
  }
}

Styxie.Initializers.StatsKeywords = {
  index: (params) => {
    $(() => initKeywords(params))
  }
}
